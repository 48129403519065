import { skipLoading } from '../../common/functions/loading';

export default {
  data() {
    return {
      workflowAjusteVerbaResource: this.$api.workflowAjusteVerba(this.$resource),
      statusPassos: {},
      ajusteVerbaSelecionado: null,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      justificativaSomenteLeitura: false,
    };
  },
  methods: {
    aprovar(justificativa) {
      const idAjuste = this.ajusteVerbaSelecionado.id;
      this.workflowAjusteVerbaResource.aprovarPasso({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.buscarStatusFluxo(this.ajusteVerbaSelecionado, true, () => {
            if (this.ajusteVerbaSelecionado.fluxo.statusFluxo === 'APROVADO') {
              this.ajusteVerbaSelecionado.status = 'APROVADO';
              this.buscarDados();
            }
          });
          this.ajusteVerbaSelecionado.aprovadorFluxo = false;
          this.atualizarTabela();
          this.$toast(this.$tc('message.ajuste_verba_aprovado', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const idAjuste = this.ajusteVerbaSelecionado.id;
      this.workflowAjusteVerbaResource.reprovar({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.ajusteVerbaSelecionado.status = 'REPROVADO';
          this.buscarStatusFluxo(this.ajusteVerbaSelecionado);
          this.ajusteVerbaSelecionado.aprovadorFluxo = false;
          this.buscarDados();
          this.$toast(this.$tc('message.ajuste_verba_reprovado', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const idAjuste = this.ajusteVerbaSelecionado.id;
      this.workflowAjusteVerbaResource.enviarAnalise({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.ajusteVerbaSelecionado.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.ajusteVerbaSelecionado);
          this.ajusteVerbaSelecionado.aprovadorFluxo = false;
          this.buscarDados();
          this.$toast(this.$tc('message.ajuste_verba_enviado_analise', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarExecutarAnalise(ajusteVerba) {
      this.ajusteVerbaSelecionado = ajusteVerba;
      const content = this.$t('message.deseja_executar_analise');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise();
            }
          },
        ));
    },
    executarAnalise() {
      const idAjuste = this.ajusteVerbaSelecionado.id,
        ajusteVerba = this.ajusteVerbaSelecionado;
      this.ajusteVerbaResource.executarAnalise({ idAjuste }, { idAjuste })
        .then(() => this.editarAjusteVerba(ajusteVerba))
        .catch((err) => {
          this.$toast(err);
        });
    },
    verificarUsuarioSolicitante(ajusteVerba) {
      const { status, idTipoVerbaDestino } = ajusteVerba;
      if (['EM_CADASTRO', 'EM_ANALISE', 'AGUARDANDO_APROVACAO'].indexOf(status) < 0) {
        return Promise.resolve();
      }

      skipLoading();
      return this.workflowAjusteVerbaResource.solicitante({ idTipoVerba: idTipoVerbaDestino })
        .then((response) => {
          ajusteVerba.solicitanteFluxo = response.data.usuarioHabilitado;
          this.atualizarTabela();
        });
    },
    verificarUsuarioAprovador(ajusteVerba) {
      const { status, id } = ajusteVerba;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return Promise.resolve();
      }

      skipLoading();
      return this.workflowAjusteVerbaResource.aprovador({ idAjuste: id })
        .then((response) => {
          ajusteVerba.aprovadorFluxo = response.data.usuarioHabilitado;
          this.atualizarTabela();
        });
    },
    buscarStatusFluxo(ajusteVerba, recarregando, cb) {
      const { id } = ajusteVerba;
      // if (status !== 'REPROVADO' && status !== 'EM_ANALISE' && !recarregando) {
      //   return;
      // }

      skipLoading();
      this.workflowAjusteVerbaResource.status({ idAjuste: id })
        .then((response) => {
          ajusteVerba.fluxo = response.data;
          if (cb) cb();
        })
        .catch((err) => {
          if (err.status === 404) {
            return;
          }
          this.$error(this, err);
        });
    },
    abrirAcompanhamento(item) {
      const idAjuste = item.id;
      this.workflowAjusteVerbaResource
        .existeFluxoCadastrado({ idAjuste })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$tc('message.ajuste_verba_finalizado_sem_fluxo', 1);
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idAjuste);
        });
    },
    exibeModalAcompanhamento(id) {
      const idAjuste = id;
      this.workflowAjusteVerbaResource.statusPasso({ idAjuste }, { idAjuste })
        .then((res) => {
          const { passos } = res.data;
          if (passos
            && (passos[0].statusPasso === 'EM_ANALISE'
              || passos[0].statusPasso === 'REPROVADO')) {
            passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    confirmarCancelarAjusteVerba(ajusteVerba) {
      const content = this.$tc('message.deseja_cancelar_ajuste_verba', 1);
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.abrirJustificativaCancelar(ajusteVerba);
            }
          },
        ));
    },
    cancelarAjusteVerba(justificativa) {
      const idAjuste = this.ajusteVerbaSelecionado.id;
      this.workflowAjusteVerbaResource.cancelar({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.ajusteVerbaSelecionado.status = 'CANCELADO';
          this.buscarStatusFluxo(this.ajusteVerbaSelecionado);
          this.ajusteVerbaSelecionado.aprovadorFluxo = false;
          this.buscarDados();
          this.$toast(this.$tc('message.ajuste_verba_cancelado', 1));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    mostrarDetalhar(item) {
      return !this.ajusteVerbaAprovado(item);
    },
    mostrarCancelar(item) {
      if (this.ajusteVerbaAgAprovacao(item)) {
        return item.solicitanteFluxo;
      }
      return false;
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'CANCELADO';
    },
    ajusteVerbaAgAprovacao(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'AGUARDANDO_APROVACAO';
    },
    ajusteVerbaAprovado(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'APROVADO';
    },
    ajusteVerbaEmAnalise(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'EM_ANALISE';
    },
    ajusteVerbaCancelado(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'CANCELADO';
    },
    abrirJustificativaAprovar(item) {
      this.abrirJustificativaAvaliar(item, this.aprovar, false);
    },
    abrirJustificativaReprovar(item) {
      this.abrirJustificativaAvaliar(item, this.reprovar, true);
    },
    abrirJustificativaAnalise(item) {
      this.abrirJustificativaAvaliar(item, this.enviarAnalise, true);
    },
    abrirJustificativaCancelar(item) {
      this.abrirJustificativaAvaliar(item, this.cancelarAjusteVerba, true);
    },
    abrirJustificativaAvaliar(item, acaoJustificativa, obrigatorio) {
      this.justificativaObrigatoria = obrigatorio;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.ajusteVerbaSelecionado = item;
      this.$refs.modalJustificativa.open();
    },
    abrirJustificativa(ajusteVerba) {
      this.justificativa = ajusteVerba.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
  },
};

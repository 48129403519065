<template>
  <v-card style="margin-bottom:1em" class="AjusteVerbaListFiltros">
    <v-card-title>
      <div class="title">{{ $tc('title.filtro', 2) }}</div>
      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass"
        style="margin-right: 10px">
        fa-thumbtack
      </v-icon>
      <icon-filtro @click="toggleFiltros" :filtros="filtrosAjusteVerba" />
    </v-card-title>
    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        v-if="carregouCampos"
        :ordenacao-campos="ordenacaoCampos"
        :campos-formulario="camposFormulario"
        :formulario-filtros="true"
        :ignora-obrigatorios="true"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="filtrosAjusteVerba"
        ref="container">
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        text
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="accent"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import { skipLoading } from '../../common/functions/loading';

import AjusteVerbaListFiltrosCampos from './AjusteVerbaListFiltrosCampos';
import IconFiltro from '../../shared-components/filtros/IconFiltro';

export default {
  name: 'AjusteVerbaFiltros',
  mixins: [
    AjusteVerbaListFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    carregouCampos() {
      return true;
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),

      filtrosAbertos: false,
      filtrosFixados: false,

      ordenacaoCampos: [],

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosAjusteVerba: {},
      workspace: {},
      tiposDeInvestimento: null,
    };
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = 'ajuste-verba';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then(() => {
          this.workspace = {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosAjusteVerba = this.workspace.filtros || {};
          setTimeout(() => this.restaurarFiltros());
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    restaurarFiltros() {
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };
      this.$emit('AjusteVerbaListFiltros__AplicaFiltros', params);
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'ajuste-verba';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'ajuste-verba';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosAjusteVerba = {};
      setTimeout(() => this.aplicarFiltros());
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    triggerTiposInvestimento() {
      this.triggerClick('ajuste-verba-filtros-tipoinvestimento');
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    carregaCamposFiltros() {
      this.ordenacaoCampos = [];
    },
  },
};
</script>
